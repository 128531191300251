import "./Report.css";
import logo from "../../assets/images/logo.png";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getAuth} from "firebase/auth";
import {doc, getDoc, getFirestore, setDoc} from "firebase/firestore";

export function Report() {
    const {
        state: {postType, timestamp}
    } = useLocation();

    const navigate = useNavigate();

    const auth = getAuth();

    const [isLoading, setIsLoading] = useState(false);

    const [isMarketingCheck, setIsMarketingCheck] = useState(false);
    const [isIllegalCheck, setIsIllegalCheck] = useState(false);
    const [isPornoCheck, setIsPornoCheck] = useState(false);
    const [isDiscriminationCheck, setIsDiscriminationCheck] = useState(false);
    const [isSpamCheck, setIsSpamCheck] = useState(false);
    const [isPrivacyCheck, setIsPrivacyCheck] = useState(false);
    const [isEtcCheck, setIsEtcCheck] = useState(false);
    const [etcContent, setEtcContent] = useState("");

    const onIsMarketingCheckChange = (e) => {
        if (isMarketingCheck === false) {
            setIsMarketingCheck(true);
        } else {
            setIsMarketingCheck(false);
        }
    };

    const onIsIllegalCheckChange = (e) => {
        if (isIllegalCheck === false) {
            setIsIllegalCheck(true);
        } else {
            setIsIllegalCheck(false);
        }
    };

    const onIsPornoCheckChange = (e) => {
        if (isPornoCheck === false) {
            setIsPornoCheck(true);
        } else {
            setIsPornoCheck(false);
        }
    };

    const onIsDiscriminationCheckChange = (e) => {
        if (isDiscriminationCheck === false) {
            setIsDiscriminationCheck(true);
        } else {
            setIsDiscriminationCheck(false);
        }
    };

    const onIsSpamCheckChange = (e) => {
        if (isSpamCheck === false) {
            setIsSpamCheck(true);
        } else {
            setIsSpamCheck(false);
        }
    };

    const onIsPrivacyCheckChange = (e) => {
        if (isPrivacyCheck === false) {
            setIsPrivacyCheck(true);
        } else {
            setIsPrivacyCheck(false);
        }
    };

    const onIsEtcCheckChange = (e) => {
        if (isEtcCheck === false) {
            setIsEtcCheck(true);
        } else {
            setIsEtcCheck(false);
        }
    };

    const onContinueClick = async () => {
        if (auth.currentUser !== null) {
            setIsLoading(true);
            const auth = getAuth();
            const firestore = getFirestore();

            if (postType === "post") {
                const docRef = doc(firestore, "reportPost", timestamp.toString());

                await getDoc(docRef).then(async (docSnap) => {
                    if (!docSnap.exists()) {
                        await setDoc(docRef, {
                            "timestamp": timestamp,
                        });

                        const reportTimestamp = Date.now();

                        await setDoc(doc(firestore, "reportPost", timestamp.toString(), "report", reportTimestamp.toString()), {
                            "timestamp": timestamp,
                            "reportTimestamp": reportTimestamp,
                            "reporterUid": auth.currentUser.uid,
                            "isMarketingCheck": isMarketingCheck,
                            "isIllegalCheck": isIllegalCheck,
                            "isPornoCheck": isPornoCheck,
                            "isDiscriminationCheck": isDiscriminationCheck,
                            "isSpamCheck": isSpamCheck,
                            "isPrivacyCheck": isPrivacyCheck,
                            "isEtcCheck": isEtcCheck,
                            "etcContent": etcContent,
                        });
                    } else {
                        const reportTimestamp = Date.now();

                        await setDoc(doc(firestore, "reportPost", timestamp.toString(), "report", reportTimestamp.toString()), {
                            "timestamp": timestamp,
                            "reportTimestamp": reportTimestamp,
                            "reporterUid": auth.currentUser.uid,
                            "isMarketingCheck": isMarketingCheck,
                            "isIllegalCheck": isIllegalCheck,
                            "isPornoCheck": isPornoCheck,
                            "isDiscriminationCheck": isDiscriminationCheck,
                            "isSpamCheck": isSpamCheck,
                            "isPrivacyCheck": isPrivacyCheck,
                            "isEtcCheck": isEtcCheck,
                            "etcContent": etcContent,
                        });
                    }
                });
            } else if (postType === "comment") {
                const docRef = doc(firestore, "reportComment", timestamp.toString());

                await getDoc(docRef).then(async (docSnap) => {
                    if (!docSnap.exists()) {
                        await setDoc(docRef, {
                            "timestamp": timestamp,
                        });

                        const reportTimestamp = Date.now();

                        await setDoc(doc(firestore, "reportPost", timestamp.toString(), "report", reportTimestamp.toString()), {
                            "timestamp": timestamp,
                            "reportTimestamp": reportTimestamp,
                            "reporterUid": auth.currentUser.uid,
                            "isMarketingCheck": isMarketingCheck,
                            "isIllegalCheck": isIllegalCheck,
                            "isPornoCheck": isPornoCheck,
                            "isDiscriminationCheck": isDiscriminationCheck,
                            "isSpamCheck": isSpamCheck,
                            "isPrivacyCheck": isPrivacyCheck,
                            "isEtcCheck": isEtcCheck,
                            "etcContent": etcContent,
                        });
                    } else {
                        const reportTimestamp = Date.now();

                        await setDoc(doc(firestore, "reportPost", timestamp.toString(), "report", reportTimestamp.toString()), {
                            "timestamp": timestamp,
                            "reportTimestamp": reportTimestamp,
                            "reporterUid": auth.currentUser.uid,
                            "isMarketingCheck": isMarketingCheck,
                            "isIllegalCheck": isIllegalCheck,
                            "isPornoCheck": isPornoCheck,
                            "isDiscriminationCheck": isDiscriminationCheck,
                            "isSpamCheck": isSpamCheck,
                            "isPrivacyCheck": isPrivacyCheck,
                            "isEtcCheck": isEtcCheck,
                            "etcContent": etcContent,
                        });
                    }
                });
            }

            setIsLoading(false);

            alert("신고가 완료되었습니다.");

            navigate("/");
        } else {
            alert("신고하기는 로그인하여야 가능합니다.")
        }
    }

    return (
        <div className="Report">
            <Body
                isMarketingCheck={isMarketingCheck}
                isIllegalCheck={isIllegalCheck}
                isPornoCheck={isPornoCheck}
                isDiscriminationCheck={isDiscriminationCheck}
                isSpamCheck={isSpamCheck}
                isPrivacyCheck={isPrivacyCheck}
                isEtcCheck={isEtcCheck}
                onIsMarketingCheckChange={onIsMarketingCheckChange}
                onIsIllegalCheckChange={onIsIllegalCheckChange}
                onIsPornoCheckChange={onIsPornoCheckChange}
                onIsDiscriminationCheckChange={onIsDiscriminationCheckChange}
                onIsSpamCheckChange={onIsSpamCheckChange}
                onIsPrivacyCheckChange={onIsPrivacyCheckChange}
                onIsEtcCheckChange={onIsEtcCheckChange}
                etcContent={etcContent}
                setEtcContent={setEtcContent}
                onContinueClick={onContinueClick}
            />
        </div>
    );
}

function Logo() {
    return (
        <div className="Report-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function MarketingCheck(props) {
    return (
        <div className="Report-MarketingCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>홍보, 영리목적</p>
        </div>
    );
}

function IllegalCheck(props) {
    return (
        <div className="Report-IllegalCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>불법정보</p>
        </div>
    );
}

function PornoCheck(props) {
    return (
        <div className="Report-PornoCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>홍보, 영리목적</p>
        </div>
    );
}

function DiscriminationCheck(props) {
    return (
        <div className="Report-DiscriminationCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>욕설, 비방, 차별, 혐오</p>
        </div>
    );
}

function SpamCheck(props) {
    return (
        <div className="Report-SpamCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>도배, 스팸</p>
        </div>
    );
}

function PrivacyCheck(props) {
    return (
        <div className="Report-PrivacyCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>개인정보 노출, 거래</p>
        </div>
    );
}

function EtcCheck(props) {
    const onContentChange = (e) => {
        const {target: {name, value}} = e;

        props.setContent(value);
    };

    return (
        <div className="Report-EtcCheck">
            <input
                className="Report-EtcCheck-Checkbox"
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>기타</p>
            <form>
                <input
                    className="Report-EtcCheck-Content"
                    type="text"
                    placeholder="신고사유"
                    required
                    value={props.content}
                    onChange={onContentChange}/>
            </form>
        </div>
    );
}

function Continue(props) {
    return (
        <div className="Report-Continue">
            <button onClick={props.onClick}>
                신고하기
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"Report-Body"}>
            <LeftSideBar/>
            <RightSideBar/>
            <Logo/>
            <h1>신고하기</h1>
            <MarketingCheck checked={props.isMarketingCheck} onChange={props.onIsMarketingCheckChange}/>
            <IllegalCheck checked={props.isIllegalCheck} onChange={props.onIsIllegalCheckChange}/>
            <PornoCheck checked={props.isPornoCheck} onChange={props.onIsPornoCheckChange}/>
            <DiscriminationCheck checked={props.isDiscriminationCheck} onChange={props.onIsDiscriminationCheckChange}/>
            <SpamCheck checked={props.isSpamCheck} onChange={props.onIsSpamCheckChange}/>
            <PrivacyCheck checked={props.isPrivacyCheck} onChange={props.onIsPrivacyCheckChange}/>
            <EtcCheck
                checked={props.isEtcCheck}
                onChange={props.onIsEtcCheckChange}
                content={props.etcContent}
                setContent={props.setEtcContent}
            />
            <Continue onClick={props.onContinueClick}/>
        </div>
    );
}
