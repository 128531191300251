import "./Login.css";
import logo from "../../assets/images/logo.png";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import React, {useState} from "react";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {Loading} from "../loading/Loading";

export function Login() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onEmailChange = (e) => {
        const {target: {name, value}} = e;

        setEmail(value);
    };

    const onPasswordChange = (e) => {
        const {target: {name, value}} = e;

        setPassword(value);
    };

    const onEmailVerificationClick = () => {
        navigate("/email_verification");
    };

    const onPasswordResetClick = () => {
        navigate("/password_reset");
    };

    const onSignInClick = () => {
        setIsLoading(true);

        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;

                if (userCredential.isEmail)

                    setIsLoading(false);

                navigate("/");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                if (errorCode === "auth/invalid-credential") {
                    alert("해당 유저를 찾을 수 없습니다. 아이디와 비밀번호를 다시 확인해주세요.")
                } else if (errorCode === "auth/invalid-email") {
                    alert("이메일 주소가 올바르지 않습니다.")
                } else if (errorCode === "auth/missing-password") {
                    alert("비밀번호를 입력해주세요.")
                } else {
                    alert("서버와의 통신에 실패하였습니다.")
                }

                setIsLoading(false);
            });
    };

    const onSignUpClick = () => {
        navigate("/eula");
    };

    return (
        <div className="Login">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        email={email}
                        password={password}
                        onEmailChange={onEmailChange}
                        onPasswordChange={onPasswordChange}
                        onEmailVerificationClick={onEmailVerificationClick}
                        onPasswordResetClick={onPasswordResetClick}
                        onSignInClick={onSignInClick}
                        onSignUpClick={onSignUpClick}
                    />
            }
        </div>
    );
}

function Logo() {
    return (
        <div className="Login-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function EmailVerification(props) {
    return (
        <div className="Login-EmailVerification">
            <button onClick={props.onClick}>
                이메일 재인증
            </button>
        </div>
    );
}

function PasswordReset(props) {
    return (
        <div className="Login-PasswordReset">
            <button onClick={props.onClick}>
                비밀번호 재설정
            </button>
        </div>
    );
}

function SignIn(props) {
    return (
        <div className="Login-SignIn">
            <button onClick={props.onClick}>
                로그인
            </button>
        </div>
    );
}

function SignUp(props) {
    return (
        <div className="Login-SignUp">
            <button onClick={props.onClick}>
                회원 가입
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"Login-Body"}>
            <LeftSideBar/>
            <RightSideBar/>
            <Logo/>
            <form>
                <input
                    className="Login-email"
                    type="text"
                    placeholder="아이디(이메일 주소)"
                    required
                    value={props.email}
                    onChange={props.onEmailChange}/>
                <input
                    className="Login-password"
                    type="password"
                    placeholder="비밀번호"
                    required
                    value={props.password}
                    onChange={props.onPasswordChange}/>
            </form>
            <EmailVerification onClick={props.onEmailVerificationClick}/>
            <PasswordReset onClick={props.onPasswordResetClick}/>
            <SignIn onClick={props.onSignInClick}/>
            <SignUp onClick={props.onSignUpClick}/>
        </div>
    );
}
