import "./MobileEula.css";
import logo from "../../assets/images/logo.png";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {MobileMenuIcon} from "../MobileMenuIcon";

export function MobileEula() {
    const navigate = useNavigate();

    const [isEulaCheck, setIsEulaCheck] = useState(false);
    const [isAgeCheck, setIsAgeCheck] = useState(false);

    const onIsEulaCheckChange = (e) => {
        if (isEulaCheck === false) {
            setIsEulaCheck(true);
        } else {
            setIsEulaCheck(false);
        }
    };

    const onIsAgeCheckChange = (e) => {
        if (isAgeCheck === false) {
            setIsAgeCheck(true);
        } else {
            setIsAgeCheck(false);
        }
    };

    const onContinueClick = () => {
        if (isEulaCheck && isAgeCheck) {
            navigate("/sign_up");
        } else {
            alert("필수 약관에 모두 동의하셔야 서비스 이용이 가능합니다.")
        }
    };

    return (
        <div className="MobileEula">
            <Body
                isEulaCheck={isEulaCheck}
                isAgeCheck={isAgeCheck}
                onIsEulaCheckChange={onIsEulaCheckChange}
                onIsAgeCheckChange={onIsAgeCheckChange}
                onContinueClick={onContinueClick}
            />
        </div>
    );
}

function Logo() {
    return (
        <div className="MobileEula-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function EulaCheck(props) {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer")

        if (newWindow === null) {
            newWindow.opener = null
        }
    };

    return (
        <div className="MobileEula-EulaCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>[필수] 서비스 이용약관 동의</p>
            <button onClick={() => openInNewTab("https://sites.google.com/view/giggles-expert")}>
                보기
            </button>
        </div>
    );
}

function AgeCheck(props) {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

        if (newWindow === null) {
            newWindow.opener = null
        }
    };

    return (
        <div className="MobileEula-AgeCheck">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
            />
            <p>[필수] 개인정보처리방침 동의</p>
            <button onClick={() => openInNewTab("https://sites.google.com/view/giggles-expert")}>
                보기
            </button>
        </div>
    );
}

function Continue(props) {
    return (
        <div className="MobileEula-Continue">
            <button onClick={props.onClick}>
                계속하기
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"MobileEula-Body"}>
            <MobileMenuIcon/>
            <Logo/>
            <h1>서비스 약관 동의</h1>
            <p>환영합니다. Giggles 입니다. 이용약관 및 개인정보처리방침에 동의하시면 서비스 이용이 가능합니다.</p>
            <EulaCheck checked={props.isEulaCheck} onChange={props.onIsEulaCheckChange}/>
            <AgeCheck checked={props.isAgeCheck} onChange={props.onIsAgeCheckChange}/>
            <Continue
                onIsEulaCheckChange={props.onIsEulaCheckChange}
                onIsAgeCheckChange={props.onIsAgeCheckChange}
                onClick={props.onContinueClick}
            />
        </div>
    );
}
