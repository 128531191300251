import React, {useEffect, useMemo, useRef, useState} from "react";
import ReactQuill from "react-quill";
import "./Edit.css";
import logo from "../../assets/images/logo.png";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {getAuth} from "firebase/auth";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import {Loading} from "../loading/Loading";
import {doc, getDoc, getFirestore, updateDoc} from "firebase/firestore";
import {useLocation, useNavigate} from "react-router-dom";

export function Edit() {
    const {
        state: {timestamp, authorUid}
    } = useLocation();

    const quillRef = useRef(null);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState("");

    const getFileDataUrl = (file) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
    };

    const imageHandler = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.setAttribute("multiple", "multiple");
        input.click();

        input.addEventListener("change", async () => {
            const storage = getStorage();

            const editor = quillRef.current.getEditor();
            const range = editor.getSelection(true);

            try {
                for (let i = 0; i < input.files.length; i++) {
                    const file = input.files[i];

                    const storageRef = ref(storage, `image/${Date.now()}`);

                    await uploadBytes(storageRef, file).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            editor.insertEmbed(range.index, "image", url);
                            editor.setSelection(range.index + 1);
                        });
                    });
                }
            } catch (error) {
                alert("서버와의 통신에 실패하였습니다.");
            }
        });
    };

    const toolbarOptions = useMemo(() => ({
        toolbar: {
            container: [
                ["bold", "underline", "italic"],
                [
                    {"list": "ordered"},
                    {"list": "bullet"},
                    {"color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]},
                    {"background": []},
                ],
                ["image", "video", "link"],
            ],
            handlers: {image: imageHandler},
        }
    }), []);

    const onClick = async (props) => {
        setIsLoading(true);

        const auth = getAuth();
        const firestore = getFirestore();

        if (auth.currentUser !== null) {
            await updateDoc(doc(firestore, "board", timestamp.toString()), {
                "content": content,
            });

            navigate("/read", {state: {timestamp: timestamp, authorUid: authorUid}});
        } else {
            alert("글 쓰기는 로그인하여야 가능합니다.")
        }

        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);

        const firestore = getFirestore();

        const docRef = doc(firestore, "board", timestamp.toString());
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("content")) {
                setContent(docSnap.data()["content"]);
            }
        });

        setIsLoading(false);
    }, []);

    return (
        <div className="Edit">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        quillRef={quillRef}
                        toolbarOptions={toolbarOptions}
                        content={content}
                        setContent={setContent}
                        onClick={onClick}
                    />
            }
        </div>
    );
}

function Logo() {
    return (
        <div className="Edit-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function Submit(props) {
    return (
        <div className="Edit-Submit">
            <button onClick={props.onClick}>
                수정하기
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className="Edit-Body">
            <Logo/>
            <LeftSideBar/>
            <RightSideBar/>
            <ReactQuill
                className="Edit-Quill"
                ref={props.quillRef}
                modules={props.toolbarOptions}
                value={props.content}
                onChange={props.setContent}
            />
            <Submit onClick={props.onClick} quillRef={props.quillRef}/>
        </div>
    );
}