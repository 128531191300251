import {Circles} from "react-loader-spinner";
import "./MobileLoading.css";

export function MobileLoading() {
    return (
        <div className="MobileLoading">
            <Circles
                color="#84bdcf"
                width={50}
                height={50}
            />
        </div>
    );
}