import {useLocation, useNavigate} from "react-router-dom";
import {Loading} from "../loading/Loading";
import React, {useEffect, useMemo, useState} from "react";
import ReactQuill from "react-quill";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc
} from "firebase/firestore";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import defaultAvatar from "../../assets/images/default_avatar.png";
import {getAuth} from "firebase/auth";
import {Pencil, ThumbsDown, ThumbsUp, Warning} from "@phosphor-icons/react";
import "./Read.css";
import {useBottomScrollListener} from "react-bottom-scroll-listener";

export function Read() {
    const {
        state: {timestamp, authorUid}
    } = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState("");
    const [comments, setComments] = useState([]);
    const [quotedCommentTimestamp, setQuotedCommentTimestamp] = useState(undefined);
    const [lastVisible, setLastVisible] = useState(undefined);

    const firestore = getFirestore();

    const getNextComments = () => {
        setIsLoading(true);

        const firestore = getFirestore();

        let q;
        if (lastVisible === -1) {
            setIsLoading(false);
            return;
        } else if (lastVisible) {
            q = query(collection(firestore, "board", timestamp.toString(), "comment"), orderBy("timestamp", "desc"), limit(10), startAfter(lastVisible));
        } else {
            q = query(collection(firestore, "board", timestamp.toString(), "comment"), orderBy("timestamp", "desc"), limit(10));
        }

        onSnapshot(q, async (snapshot) => {
            const newComments = snapshot.docs.map((elem) => elem.data());

            setComments([...comments, ...newComments]);

            if (snapshot.docs.length === 0) {
                setLastVisible(-1);
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });

        setIsLoading(false);
    }

    useBottomScrollListener(getNextComments);

    useEffect(() => {
        setIsLoading(true);

        const docRef = doc(firestore, "board", timestamp.toString());
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("content")) {
                setContent(docSnap.data()["content"]);
            }
        });

        setLastVisible(undefined);
        getNextComments();

        setIsLoading(false);
    }, []);

    const toolbarOptions = useMemo(() => ({
        toolbar: null
    }), []);

    return (
        <div className="Read">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        timestamp={timestamp}
                        authorUid={authorUid}
                        content={content}
                        toolbarOptions={toolbarOptions}
                        comments={comments}
                        setIsLoading={setIsLoading}
                    />
            }
        </div>
    );
}

function Document(props) {
    return (
        <div className="Read-Document">
            <Avatar
                authorUid={props.authorUid}
            />
            <Nickname
                authorUid={props.authorUid}
            />
            <Dropdown
                timestamp={props.timestamp}
                authorUid={props.authorUid}
                setIsLoading={props.setIsLoading}
            />
            <ReactQuill
                value={props.content}
                readOnly={true}
                theme={"snow"}
                modules={props.toolbarOptions}
            />
            <NumUps timestamp={props.timestamp}/>
            <NumDowns timestamp={props.timestamp}/>
        </div>
    );
}

function Avatar(props) {
    const navigate = useNavigate();

    const [authorAvatarUrl, setAuthorAvatarUrl] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("avatarUrl")) {
            setAuthorAvatarUrl(docSnap.data()["avatarUrl"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (authorAvatarUrl !== "") {
        return (
            <div className="Read-Avatar">
                <img src={authorAvatarUrl} onClick={onClick}/>
            </div>
        );
    } else {
        return (
            <div className="Read-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        );
    }
}

function Nickname(props) {
    const navigate = useNavigate();

    const [nickname, setNickname] = useState("");

    const firestore = getFirestore();

    const docRef = doc(firestore, "user", props.authorUid);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("nickname")) {
            setNickname(docSnap.data()["nickname"]);
        }
    });

    const onClick = () => {
        navigate("/profile_view", {uid: props.authorUid});
    };

    if (nickname !== "") {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    {nickname}
                </button>
            </div>
        );
    } else {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        );
    }
}

function Dropdown(props) {
    const navigate = useNavigate();

    const auth = getAuth();

    let isEdit = false;
    if (auth.currentUser !== null && auth.currentUser.uid === props.authorUid) {
        isEdit = true;
    }

    const [dropdown, setDropdown] = useState(false);

    const onDropdownClick = () => {
        if (dropdown === true) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    const onEditClick = () => {
        navigate("/edit", {state: {timestamp: props.timestamp, authorUid: props.authorUid}})
    }

    const onDeleteClick = async () => {
        const answer = window.confirm("정말 삭제하시겠습니까?");

        if (answer === true) {
            props.setIsLoading(true);

            const firestore = getFirestore();

            await deleteDoc(doc(firestore, "board", props.timestamp.toString()));
            await deleteDoc(doc(firestore, "metadata", props.timestamp.toString()));

            props.setIsLoading(false);

            navigate("/");
        }
    }

    const onReportClick = () => {
        if (auth.currentUser !== null) {
            navigate("/report", {state: {postType: "post", timestamp: props.timestamp}});
        } else {
            navigate("/login");
        }
    }

    if (isEdit === true) {
        if (dropdown === true) {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Pencil size={24}/>
                    </botton>
                    <botton className="Read-Dropdown-Edit" onClick={onEditClick}>수정하기</botton>
                    <botton className="Read-Dropdown-Delete" onClick={onDeleteClick}>삭제하기</botton>
                </div>
            );
        } else {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Pencil size={24}/>
                    </botton>
                </div>
            );
        }
    } else {
        if (dropdown === true) {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Warning color="red" size={24}/>
                    </botton>
                    <botton className="Read-Dropdown-Report" onClick={onReportClick}>신고하기</botton>
                    <botton className="Read-Dropdown-Block">차단하기</botton>
                </div>
            );
        } else {
            return (
                <div className="Read-Dropdown">
                    <botton className="Read-Dropdown-Dropdown" onClick={onDropdownClick}>
                        <Warning color="red" size={24}/>
                    </botton>
                </div>
            );
        }
    }
}

function NumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });

        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const onClick = async () => {
            if (click === true) {
                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });

                setNumUps(numUps - 1);
                setClick(false);
            } else {
                const upTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": upTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });

                setNumUps(numUps + 1);
                setClick(true);
            }
        };

        if (click === true) {
            return (
                <div className="Read-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-NumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function NumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [clickDown, setClickDown] = useState(false);

    const auth = getAuth();

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numDowns"]);
            }
        });

        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClickDown(true);
            }
        });

        const onClick = async () => {
            if (clickDown === true) {
                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });

                setNumDowns(numDowns - 1);
                setClickDown(false);
            } else {
                const downTimestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": downTimestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });

                setNumDowns(numDowns + 1);
                setClickDown(true);
            }
        };

        if (clickDown === true) {
            return (
                <div className="Read-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-NumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-NumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function Comments(props) {
    const comments = props.comments.map((item, i) => {
        return <Comment timestamp={props.timestamp} comment={item}/>;
    });

    return (
        <div className="Read-Comments">
            {comments}
        </div>
    );
}

function Comment(props) {
    return (
        <div className="Read-Comment">
            <Avatar
                authorUid={props.comment["authorUid"]}
            />
            <Nickname
                authorUid={props.comment["authorUid"]}
            />
            <CommentBody content={props.comment["content"]}/>
            <CommentNumUps
                timestamp={props.timestamp}
                commentTimestamp={props.comment["timestamp"]}
            />
            <CommentNumDowns
                timestamp={props.timestamp}
                commentTimestamp={props.comment["timestamp"]}
            />
        </div>
    );
}

function CommentNumUps(props) {
    const navigate = useNavigate();

    const [numUps, setNumUps] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"]);
            }
        });

        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "up", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const onClick = async () => {
            if (click === true) {
                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                });

                setNumUps(numUps - 1);
                setClick(false);
            } else {
                const timestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": timestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                });

                setNumUps(numUps + 1);
                setClick(true);
            }
        };

        if (click === true) {
            return (
                <div className="Read-CommentNumUps">
                    <button onClick={onClick}>
                        <ThumbsUp color="#e7fbff" size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-CommentNumUps">
                    <button onClick={onClick}>
                        <ThumbsUp size={24}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-CommentNumUps">
                <button onClick={onClick}>
                    <ThumbsUp size={24}/>
                    <p>{numUps}</p>
                </button>
            </div>
        );
    }
}

function CommentNumDowns(props) {
    const navigate = useNavigate();

    const [numDowns, setNumDowns] = useState(0);
    const [click, setClick] = useState(false);

    const auth = getAuth();

    if (auth.currentUser !== null) {
        const firestore = getFirestore();

        const summaryDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString());
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numUps"]);
            }
        });

        const metadataDocRef = doc(firestore, "metadata", props.timestamp.toString(), "comment", props.commentTimestamp.toString(), "down", auth.currentUser.uid);
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true);
            }
        });

        const onClick = async () => {
            if (click === true) {
                await deleteDoc(metadataDocRef);

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                });

                setNumDowns(numDowns - 1);
                setClick(false);
            } else {
                const timestamp = Date.now();

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser.uid,
                    "timestamp": timestamp,
                });

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                });

                setNumDowns(numDowns + 1);
                setClick(true);
            }
        };

        if (click === true) {
            return (
                <div className="Read-CommentNumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown color="#e7fbff" size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-CommentNumDowns">
                    <button onClick={onClick}>
                        <ThumbsDown size={24}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login");
        };

        return (
            <div className="Read-CommentNumDowns">
                <button onClick={onClick}>
                    <ThumbsDown size={24}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        );
    }
}

function CommentBody(props) {
    return (
        <div className="Read-CommentBody">
            <p>
                {props.content}
            </p>
        </div>
    );
}

function CommentInput(props) {
    const [content, setContent] = useState("");

    const onContentChange = (e) => {
        const {target: {name, value}} = e;

        setContent(value);
    };

    if (props.quotedCommentTimestamp === undefined) {
        return <div className="Read-CommentInput">
            <form>
                <input
                    className="Read-CommentInput-Content"
                    type="text"
                    placeholder="댓글"
                    required
                    value={content}
                    onChange={onContentChange}/>
            </form>
            <CommentSubmit
                timestamp={props.timestamp}
                content={content}
                setContent={setContent}
                setIsLoading={props.setIsLoading}
            />
        </div>
    } else {
        return <div className="Read-CommentInput">
            <form>
                <input
                    className="Read-CommentInput-Content"
                    type="text"
                    placeholder="댓글"
                    required
                    value={content}
                    onChange={onContentChange}/>
            </form>
            <CommentSubmit
                timestamp={props.timestamp}
                content={content}
                setContent={setContent}
                setIsLoading={props.setIsLoading}
            />
        </div>
    }
}

function CommentSubmit(props) {
    const onClick = async () => {
        if (props.content !== "") {
            props.setIsLoading(true);

            const auth = getAuth();
            const firestore = getFirestore();

            const commentTimestamp = Date.now();

            if (auth.currentUser !== null) {
                await updateDoc(doc(firestore, "metadata", props.timestamp.toString()), {
                    "numComments": increment(1),
                });

                await setDoc(doc(firestore, "board", props.timestamp.toString(), "comment", commentTimestamp.toString()), {
                    "authorUid": auth.currentUser.uid,
                    "content": props.content,
                    "timestamp": commentTimestamp,
                    "numUps": 0,
                    "numDowns": 0,
                });

                await setDoc(doc(firestore, "metadata", props.timestamp.toString(), "comment", commentTimestamp.toString()), {
                    "authorUid": auth.currentUser.uid,
                    "timestamp": commentTimestamp,
                });
            } else {
                alert("댓글 쓰기는 로그인하여야 가능합니다.")
            }

            props.setContent("");
            props.setIsLoading(false);
        }
    };

    return <div className="Read-CommentSubmit">
        <button onClick={onClick}>
            댓글 쓰기
        </button>
    </div>
}

function Body(props) {
    return (
        <div className="Read-Body">
            <LeftSideBar/>
            <Document
                timestamp={props.timestamp}
                authorUid={props.authorUid}
                content={props.content}
                numUps={props.numUps}
                numDowns={props.numDowns}
                toolbarOptions={props.toolbarOptions}
                setIsLoading={props.setIsLoading}
            />
            <Comments timestamp={props.timestamp} comments={props.comments}/>
            <CommentInput
                timestamp={props.timestamp}
                quotedCommentTimestamp={props.quotedCommentTimestamp}
                setIsLoading={props.setIsLoading}
            />
            <RightSideBar/>
        </div>
    );
}
