import React, {useEffect, useRef, useState} from "react";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {getAuth} from "firebase/auth";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import {Loading} from "../loading/Loading";
import {useNavigate} from "react-router-dom";
import "./Profile.css";
import {doc, getDoc, getFirestore, updateDoc} from "firebase/firestore";
import defaultAvatar from "../../assets/images/default_avatar.png";
import imageCompression from "browser-image-compression";

export function Profile() {
    const navigate = useNavigate();

    const avatarInputRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState("");
    const [nickname, setNickname] = useState("");
    const [numPosts, setNumPosts] = useState(0);
    const [numComments, setNumComments] = useState(0);
    const [numFollowers, setNumFollowers] = useState(0);
    const [numFollowings, setNumFollowings] = useState(0);
    const [bio, setBio] = useState("");
    const [hashtags, setHashtags] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        const auth = getAuth();

        if (auth.currentUser !== null) {
            const uid = auth.currentUser.uid;

            try {
                const firestore = getFirestore();

                const docRef = doc(firestore, "user", uid);
                getDoc(docRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        if (docSnap.data().hasOwnProperty("avatarUrl")) {
                            setAvatarUrl(docSnap.data()["avatarUrl"]);
                        } else {
                            setAvatarUrl("");
                        }

                        if (docSnap.data().hasOwnProperty("nickname")) {
                            setNickname(docSnap.data()["nickname"]);
                        } else {
                            setNickname("");
                        }

                        if (docSnap.data().hasOwnProperty("numPosts")) {
                            setNumPosts(docSnap.data()["numPosts"]);
                        } else {
                            setNumPosts(0);
                        }

                        if (docSnap.data().hasOwnProperty("numComments")) {
                            setNumComments(docSnap.data()["numComments"]);
                        } else {
                            setNumComments(0);
                        }

                        if (docSnap.data().hasOwnProperty("numFollowers")) {
                            setNumFollowers(docSnap.data()["numFollowers"]);
                        } else {
                            setNumFollowers(0);
                        }

                        if (docSnap.data().hasOwnProperty("numFollowings")) {
                            setNumFollowings(docSnap.data()["numFollowings"]);
                        } else {
                            setNumFollowings(0);
                        }

                        if (docSnap.data().hasOwnProperty("bio")) {
                            setBio(docSnap.data()["bio"]);
                        } else {
                            setBio("");
                        }

                        if (docSnap.data().hasOwnProperty("hashtags")) {
                            setHashtags(docSnap.data()["hashtags"].split(","));
                        } else {
                            setHashtags([]);
                        }
                    } else {
                        setAvatarUrl("");
                        setNickname("");
                        setNumPosts(0);
                        setNumComments(0);
                        setNumFollowers(0);
                        setNumFollowings(0);
                        setBio("");
                        setHashtags([]);
                    }
                });
            } catch (error) {
                setAvatarUrl("");
                setNickname("");
                setNumPosts(0);
                setNumComments(0);
                setNumFollowers(0);
                setNumFollowings(0);
                setBio("");
                setHashtags([]);
            }
        } else {
            navigate("/login");
        }

        setIsLoading(false);
    }, []);

    const onAvatarEditClick = () => {
        const auth = getAuth();

        if (auth.currentUser !== null) {
            avatarInputRef.current.click();
        } else {
            navigate("/login");
        }
    };

    const onAvatarEditChange = (e) => {
        const auth = getAuth();

        if (auth.currentUser !== null) {
            const avatarUpload = e.target.files[0];

            if (avatarUpload !== null) {
                setIsLoading(true);

                const uid = auth.currentUser.uid;

                const storage = getStorage();

                const avatarRef = ref(storage, "profile/" + uid);

                try {
                    imageCompression(avatarUpload, {maxSizeMB: 0.001}).then((compressed) => {
                        uploadBytes(avatarRef, compressed).then((snapshot) => {
                            getDownloadURL(snapshot.ref).then((downloadURL) => {
                                setAvatarUrl(downloadURL);

                                const firestore = getFirestore();

                                const docRef = doc(firestore, "user", uid);
                                updateDoc(docRef, {
                                    "avatarUrl": downloadURL,
                                });
                            });
                        });
                    })
                } catch (error) {
                    alert("서버와의 통신에 실패하였습니다");
                }

                setIsLoading(false);
            } else {
                alert("이미지 파일을 선택해주세요");
            }
        } else {
            navigate("/login");
        }
    };

    const onEditClick = () => {
        const auth = getAuth();

        if (auth.currentUser !== null) {
            if (isEditing === false) {
                setIsEditing(true);
            } else {
                setIsLoading(true);

                const uid = auth.currentUser.uid;

                const firestore = getFirestore();

                try {
                    const docRef = doc(firestore, "user", uid);
                    updateDoc(docRef, {
                        "nickname": nickname,
                        "bio": bio,
                        "hashtags": hashtags.join(","),
                    });
                } catch (error) {
                    alert("서버와의 통신에 실패하였습니다");
                }

                setIsEditing(false);
                setIsLoading(false);
            }
        } else {
            navigate("/login");
        }
    };

    const onNicknameChange = (e) => {
        const {target: {name, value}} = e;

        setNickname(value);
    };

    const onNumPostsClick = () => {
        navigate("/posts");
    };

    const onNumCommentsClick = () => {
        navigate("/comments");
    };

    const onNumFollowersClick = () => {
        navigate("/followers");
    };

    const onNumFollowingsClick = () => {
        navigate("/followings");
    };

    const onBioChange = (e) => {
        const {target: {name, value}} = e;

        setBio(value);
    };

    const addHashtag = (event) => {
        const inputVal = event.target.value;

        if (event.key === "Enter" && inputVal !== "" && !hashtags.includes(inputVal)) {
            if (inputVal[0] !== "#") {
                setHashtags([...hashtags, "#" + inputVal]);
            } else {
                setHashtags([...hashtags, inputVal]);
            }

            const auth = getAuth();

            const uid = auth.currentUser.uid;

            const firestore = getFirestore();

            try {
                const docRef = doc(firestore, "user", uid);
                updateDoc(docRef, {
                    "nickname": nickname,
                    "bio": bio,
                });
            } catch (error) {
                alert("서버와의 통신에 실패하였습니다");
            }

            event.target.value = "";
        }
    };

    const removeHashtag = (indexToRemove) => {
        const filter = hashtags.filter((element, index) => index !== indexToRemove);
        setHashtags(filter);

        const auth = getAuth();

        const uid = auth.currentUser.uid;

        const firestore = getFirestore();

        try {
            const docRef = doc(firestore, "user", uid);
            updateDoc(docRef, {
                "nickname": nickname,
                "bio": bio,
                "hashtags": hashtags.join(","),
            });
        } catch (error) {
            alert("서버와의 통신에 실패하였습니다");
        }
    };

    return (
        <div className="Profile">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        avatarUrl={avatarUrl}
                        onAvatarEditClick={onAvatarEditClick}
                        avatarInputRef={avatarInputRef}
                        onAvatarEditChange={onAvatarEditChange}
                        nickname={nickname}
                        onNicknameChange={onNicknameChange}
                        numPosts={numPosts}
                        onNumPostsClick={onNumPostsClick}
                        numComments={numComments}
                        onNumCommentsClick={onNumCommentsClick}
                        numFollowers={numFollowers}
                        onNumFollowersClick={onNumFollowersClick}
                        numFollowings={numFollowings}
                        onNumFollowingsClick={onNumFollowingsClick}
                        bio={bio}
                        onBioChange={onBioChange}
                        hashtags={hashtags}
                        addHashtag={addHashtag}
                        removeHashtag={removeHashtag}
                        isEditing={isEditing}
                        onEditClick={onEditClick}
                    />
            }
        </div>
    );
}

function Avatar(props) {
    if (props.url !== "") {
        return (
            <div className="Profile-Avatar">
                <img src={props.url} onClick={props.onClick}/>
                <input
                    ref={props.inputRef}
                    onChange={props.onChange}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    style={{display: 'none'}}/>
            </div>
        );
    } else {
        return (
            <div className="Profile-Avatar">
                <img src={defaultAvatar} onClick={props.onClick}/>
                <input
                    ref={props.inputRef}
                    onChange={props.onChange}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    style={{display: 'none'}}/>
            </div>
        );
    }
}

function Nickname(props) {
    if (props.nickname !== "") {
        return (
            <div className="Profile-Nickname">
                {
                    props.isEditing ?
                        <input
                            type="text"
                            placeholder={props.nickname}
                            required
                            value={props.nickname}
                            onChange={props.onChange}/> :
                        <p>{props.nickname}</p>
                }
            </div>
        );
    } else {
        return (
            <div className="Profile-Nickname">
                {
                    props.isEditing ?
                        <input
                            type="text"
                            placeholder={props.nickname}
                            required
                            value={props.nickname}
                            onChange={props.onChange}/> :
                        <p>닉네임 없음</p>
                }
            </div>
        );
    }
}

function NumPosts(props) {
    return (
        <div className="Profile-NumPosts">
            <button onClick={props.onClick}>
                {props.numPosts} 글
            </button>
        </div>
    );
}

function NumComments(props) {
    return (
        <div className="Profile-NumComments">
            <button onClick={props.onClick}>
                {props.numComments} 댓글
            </button>
        </div>
    );
}

function NumFollowers(props) {
    return (
        <div className="Profile-NumFollowers">
            <button onClick={props.onClick}>
                {props.numFollowers} 팔로워
            </button>
        </div>
    );
}

function NumFollowings(props) {
    return (
        <div className="Profile-NumFollowings">
            <button onClick={props.onClick}>
                {props.numFollowings} 팔로잉
            </button>
        </div>
    );
}

function Bio(props) {
    if (props.bio !== "") {
        return (
            <div className="Profile-Bio">
                <h1>자기소개</h1>
                {
                    props.isEditing ?
                        <input
                            type="text"
                            placeholder={props.bio}
                            required
                            value={props.bio}
                            onChange={props.onChange}/> :
                        <p>{props.bio}</p>
                }
            </div>
        );
    } else {
        return (
            <div className="Profile-Bio">
                <h1>자기소개</h1>
                {
                    props.isEditing ?
                        <input
                            type="text"
                            placeholder={props.bio}
                            required
                            value={props.bio}
                            onChange={props.onChange}/> :
                        <p>자기소개 없음</p>
                }
            </div>
        );
    }
}

function Hashtags(props) {
    if (props.isEditing === false) {
        return (
            <div className="Profile-Hashtags">
                <h1>관심주제(해시태그)</h1>
                {
                    <ul>
                        {props.hashtags.map((hashtag, index) => (
                            <li key={index}>
                                <span className="Profile-Hashtags-tag">{hashtag}</span>
                                <span className="Profile-Hashtags-icon"
                                      onClick={() => props.removeHashtag(index)}>X</span>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        );
    } else {
        return (
            <div className="Profile-Hashtags">
                <h1>관심주제(해시태그)</h1>
                {
                    <input
                        type="text"
                        onKeyUp={(e) => props.addHashtag(e)}
                        placeholder="해시태그를 입력해주세요"
                    />
                }
                {
                    <ul>
                        {props.hashtags.map((hashtag, index) => (
                            <li key={index}>
                                <span className="Profile-Hashtags-tag">{hashtag}</span>
                                <span className="Profile-Hashtags-icon"
                                      onClick={() => props.removeHashtag(index)}>X</span>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        );
    }
}

function Edit(props) {
    return (
        <div className="Profile-Edit">
            <button onClick={props.onClick}>
                프로필 수정하기
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"Profile-Body"}>
            <LeftSideBar/>
            <RightSideBar/>
            <Avatar
                url={props.avatarUrl}
                onClick={props.onAvatarEditClick}
                inputRef={props.avatarInputRef}
                onChange={props.onAvatarEditChange}
            />
            <Nickname
                nickname={props.nickname}
                isEditing={props.isEditing}
                onChange={props.onNicknameChange}
            />
            <NumPosts
                numPosts={props.numPosts}
                onClick={props.onNumPostsClick}
            />
            <NumComments
                numComments={props.numComments}
                onClick={props.onNumCommentsClick}
            />
            <NumFollowers
                numFollowers={props.numFollowers}
                onClick={props.onNumFollowersClick}
            />
            <NumFollowings
                numFollowings={props.numFollowings}
                onClick={props.onNumFollowingsClick}
            />
            <NumComments
                numComments={props.numComments}
                onClick={props.onNumCommentsClick}
            />
            <Bio
                bio={props.bio}
                isEditing={props.isEditing}
                onChange={props.onBioChange}
            />
            <Hashtags
                hashtags={props.hashtags}
                isEditing={props.isEditing}
                addHashtag={props.addHashtag}
                removeHashtag={props.removeHashtag}
            />
            <Edit onClick={props.onEditClick}/>
        </div>
    );
}
