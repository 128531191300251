import "./SignUp.css";
import logo from "../../assets/images/logo.png";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import React, {useState} from "react";
import {createUserWithEmailAndPassword, deleteUser, getAuth, sendEmailVerification, signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {Loading} from "../loading/Loading";
import {deleteDoc, doc, getFirestore, setDoc} from "firebase/firestore";

export function SignUp() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onEmailChange = (e) => {
        const {target: {name, value}} = e;

        setEmail(value);
    };

    const onPasswordChange = (e) => {
        const {target: {name, value}} = e;

        setPassword(value);
    };

    const onSignUpClick = async () => {
        setIsLoading(true);

        const auth = getAuth();

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                if (auth.currentUser !== null) {
                    deleteUser(auth.currentUser);
                    signOut(auth);
                }

                if (errorCode === "auth/email-already-in-use") {
                    alert("해당 이메일은 이미 사용 중입니다.");
                } else if (errorCode === "auth/invalid-email") {
                    alert("이메일 주소가 올바르지 않습니다.");
                } else if (errorCode === "auth/missing-password") {
                    alert("비밀번호를 입력해주세요.");
                } else {
                    alert("서버와의 통신에 실패하였습니다.");
                }

                setIsLoading(false);
            });

        const uid = auth.currentUser.uid;

        const firestore = getFirestore();

        const docRef = doc(firestore, "user", uid);
        await setDoc(docRef, {
            "uid": uid,
        })
            .then(() => {
            })
            .catch((error) => {
                deleteUser(auth.currentUser);
                signOut(auth);

                alert("서버와의 통신에 실패하였습니다.");

                setIsLoading(false);
            });

        const user = auth.currentUser;

        await sendEmailVerification(user)
            .then(() => {
                setIsLoading(false);

                signOut(auth);
                navigate("/");

                alert("메일함으로 가서 이메일을 인증해주세요");
            })
            .catch((error) => {
                deleteUser(auth.currentUser);
                signOut(auth);

                const docRef = doc(firestore, "user", uid);
                deleteDoc(docRef);

                alert("서버와의 통신에 실패하였습니다.");

                setIsLoading(false);
            });
    };

    return (
        <div className="SignUp">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        email={email}
                        password={password}
                        onEmailChange={onEmailChange}
                        onPasswordChange={onPasswordChange}
                        onSignUpClick={onSignUpClick}
                    />
            }
        </div>
    );
}

function Logo() {
    return (
        <div className="SignUp-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"SignUp-Body"}>
            <LeftSideBar/>
            <RightSideBar/>
            <Logo/>
            <form>
                <input
                    className="SignUp-email"
                    type="text"
                    placeholder="아이디(이메일 주소)"
                    required
                    value={props.email}
                    onChange={props.onEmailChange}/>
                <input
                    className="SignUp-password"
                    type="password"
                    placeholder="비밀번호"
                    required
                    value={props.password}
                    onChange={props.onPasswordChange}/>
            </form>
            <button onClick={props.onSignUpClick}>
                회원 가입
            </button>
        </div>
    );
}
