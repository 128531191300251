import "./Setting.css";
import logo from "../../assets/images/logo.png";
import {LeftSideBar} from "../LeftSideBar";
import {RightSideBar} from "../RightSideBar";
import React, {useState} from "react";
import {deleteUser, getAuth, signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {Loading} from "../loading/Loading";

export function Setting() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const onUnregisterClick = () => {
        setIsLoading(true);

        const auth = getAuth();
        const user = auth.currentUser;

        if (user !== null) {
            deleteUser(user);
            signOut(auth);

            navigate("/");
        } else {
            navigate("/login");

            alert("로그인을 해주세요");
        }

        setIsLoading(false)
    };

    return (
        <div className="Setting">
            {
                isLoading ?
                    <Loading/> :
                    <Body
                        onUnregisterClick={onUnregisterClick}
                    />
            }
        </div>
    );
}

function Logo() {
    return (
        <div className="Setting-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function Unregister(props) {
    return (
        <div className="Setting-Unregister">
            <button onClick={props.onClick}>
                회원탈퇴
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"Setting-Body"}>
            <LeftSideBar/>
            <RightSideBar/>
            <Logo/>
            <Unregister onClick={props.onUnregisterClick}/>
        </div>
    );
}
