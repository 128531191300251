import "./MobilePasswordReset.css";
import logo from "../../assets/images/logo.png";
import React, {useState} from "react";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {MobileLoading} from "../loading/MobileLoading";
import {MobileMenuIcon} from "../MobileMenuIcon";

export function MobilePasswordReset() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const onChange = (e) => {
        const {target: {name, value}} = e;

        setEmail(value);
    }

    const onClick = () => {
        setIsLoading(true);

        const auth = getAuth();

        sendPasswordResetEmail(auth, email)
            .then(() => {
                setIsLoading(false);

                alert("비밀번호 재설정 이메일이 전송되었습니다.");

                navigate("/");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                if (errorCode === "auth/missing-email") {
                    alert("이메일 주소가 올바르지 않습니다.")
                } else {
                    alert("서버와의 통신에 실패하였습니다.")
                }

                setIsLoading(false);
            });
    }

    return (
        <div className="MobilePasswordReset">
            {
                isLoading ?
                    <MobileLoading/> :
                    <Body
                        email={email}
                        onChange={onChange}
                        onClick={onClick}
                    />
            }
        </div>
    );
}

function Logo() {
    return (
        <div className="MobilePasswordReset-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function SendEmail(props) {
    return (
        <div className="MobilePasswordReset-SendEmail">
            <button onClick={props.onClick}>
                비밀번호 재설정
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"MobilePasswordReset-Body"}>
            <MobileMenuIcon/>
            <Logo/>
            <form>
                <input
                    className="MobilePasswordReset-email"
                    type="text"
                    placeholder="아이디(이메일 주소)"
                    required
                    value={props.email}
                    onChange={props.onChange}/>
            </form>
            <SendEmail onClick={props.onClick}/>
        </div>
    );
}
