import {Circles} from "react-loader-spinner";
import "./Loading.css";

export function Loading() {
    return (
        <div className="Loading">
            <Circles
                color="#84bdcf"
                width={50}
                height={50}
            />
        </div>
    );
}