import {TextAlignJustify} from "@phosphor-icons/react";
import {useNavigate} from "react-router-dom";
import "./MobileMenuIcon.css";

export function MobileMenuIcon() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/menu");
    }

    return (
        <div className="MobileMenu">
            <TextAlignJustify size={32} onClick={onClick}/>
        </div>
    );
}