import React from "react";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {Coins, NotePencil, Ranking, Repeat, ShootingStar} from "@phosphor-icons/react";
import {Menu, MenuItem, Sidebar, sidebarClasses, SubMenu} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import "./SideBar.css";
import "./RightSideBar.css";
import "../firebase";
import {getAuth} from "firebase/auth";

export function RightSideBar() {
    const auth = getAuth();

    if (auth.currentUser !== null) {
        return (
            <div className="RightSideBar">
                <Sidebar rootStyles={{
                    [`.${sidebarClasses.container}`]: {
                        backgroundColor: '#e7fbff',
                        color: 'white',
                    },
                }}>
                    <Menu>
                        <SubMenu className="SideBar-subMenu" label="랭킹" icon={<ShootingStar/>}>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/raking/total"/>}
                                      icon={<Ranking size={24}/>}>
                                통합 랭킹
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/raking/total"/>}
                                      icon={<NotePencil size={24}/>}>
                                글쓰기 랭킹
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/raking/comment"/>}
                                      icon={<Repeat size={24}/>}>
                                댓글 랭킹
                            </MenuItem>
                        </SubMenu>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/raking/coin"/>}
                                  icon={<Coins size={24}/>}>
                            코인
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </div>
        );
    } else {
        return (
            <div className="RightSideBar">
                <Sidebar rootStyles={{
                    [`.${sidebarClasses.container}`]: {
                        backgroundColor: '#e7fbff',
                        color: 'white',
                    },
                }}>
                    <Menu>
                        <SubMenu className="SideBar-subMenu" label="랭킹" icon={<ShootingStar/>}>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/raking/total"/>}
                                      icon={<Ranking size={24}/>}>
                                통합 랭킹
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/raking/total"/>}
                                      icon={<NotePencil size={24}/>}>
                                글쓰기 랭킹
                            </MenuItem>
                            <MenuItem className="SideBar-menuItem" component={<Link to="/raking/comment"/>}
                                      icon={<Repeat size={24}/>}>
                                댓글 랭킹
                            </MenuItem>
                        </SubMenu>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>}
                                  icon={<Coins size={24}/>}>
                            코인
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </div>
        );
    }
}
