import React from "react";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {
    ChatsCircle,
    Coins,
    Compass,
    House,
    MagnifyingGlass,
    NotePencil,
    Notification,
    Pencil,
    Person,
    Ranking,
    Repeat,
    ShootingStar,
    SignIn,
    SignOut,
    Smiley
} from "@phosphor-icons/react";
import {Link, useNavigate} from "react-router-dom";
import {Menu, MenuItem, Sidebar, sidebarClasses, SubMenu} from "react-pro-sidebar";
import "./MobileMenu.css";
import {getAuth, signOut} from "firebase/auth";

export function MobileMenu() {
    return (
        <div className="MobileMenu">
            <Sidebar rootStyles={{
                [`.${sidebarClasses.container}`]: {
                    width: '100vw',
                    backgroundColor: '#e7fbff',
                    color: 'white',
                },
            }}>
                <Menu>
                    <MenuItem className="SideBar-menuItem" component={<Link to="/"/>} icon={<House size={24}/>}>
                        홈
                    </MenuItem>
                    <MenuItem className="SideBar-menuItem" component={<Link to="/search"/>}
                              icon={<MagnifyingGlass size={24}/>}>
                        검색
                    </MenuItem>
                    <SubMenu className="SideBar-subMenu" label="관심" icon={<Compass/>}>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/interest/subject"/>}
                                  icon={<ChatsCircle size={24}/>}>
                            주제
                        </MenuItem>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/interest/author"/>}
                                  icon={<Person size={24}/>}>
                            작가
                        </MenuItem>
                    </SubMenu>
                    <MenuItem className="SideBar-menuItem" component={<Link to="/notification"/>}
                              icon={<Notification size={24}/>}>
                        알림
                    </MenuItem>
                    <MenuItem className="SideBar-menuItem" component={<Link to="/write"/>} icon={<Pencil size={24}/>}>
                        글 쓰기
                    </MenuItem>
                    <SubMenu className="SideBar-subMenu" label="랭킹" icon={<ShootingStar/>}>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/raking/total"/>}
                                  icon={<Ranking size={24}/>}>
                            통합 랭킹
                        </MenuItem>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/raking/total"/>}
                                  icon={<NotePencil size={24}/>}>
                            글쓰기 랭킹
                        </MenuItem>
                        <MenuItem className="SideBar-menuItem" component={<Link to="/raking/comment"/>}
                                  icon={<Repeat size={24}/>}>
                            댓글 랭킹
                        </MenuItem>
                    </SubMenu>
                    <MenuItem className="SideBar-menuItem" component={<Link to="/raking/coin"/>}
                              icon={<Coins size={24}/>}>
                        코인
                    </MenuItem>
                    <MenuItem className="SideBar-menuItem" component={<Link to="/profile"/>} icon={<Smiley size={24}/>}>
                        프로필
                    </MenuItem>
                    <SignInOrOut/>
                </Menu>
            </Sidebar>
        </div>
    );
}

function SignInOrOut() {
    const navigate = useNavigate();

    const auth = getAuth();

    const onClick = () => {
        signOut(auth).then(() => {
            navigate("/");
        }).catch((error) => {
        });
    }

    if (auth.currentUser == null) {
        return (
            <MenuItem className="SideBar-menuItem" component={<Link to="/login"/>} icon={<SignIn size={24}/>}>
                로그인
            </MenuItem>
        )
    } else {
        return (
            <MenuItem className="SideBar-menuItem" onClick={onClick} icon={<SignOut size={24}/>}>
                로그아웃
            </MenuItem>
        )
    }
}
