import "./MobileEmailVerification.css";
import logo from "../../assets/images/logo.png";
import React, {useState} from "react";
import {getAuth, sendEmailVerification, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {MobileLoading} from "../loading/MobileLoading";
import {MobileMenuIcon} from "../MobileMenuIcon";

export function MobileEmailVerification() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onEmailChange = (e) => {
        const {target: {name, value}} = e;

        setEmail(value);
    }

    const onPasswordChange = (e) => {
        const {target: {name, value}} = e;

        setPassword(value);
    }

    const onClick = () => {
        setIsLoading(true);

        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;

                sendEmailVerification(user);

                signOut(auth);

                setIsLoading(false);

                alert("인증 이메일이 전송되었습니다.");

                navigate("/");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                if (errorCode === "auth/invalid-credential") {
                    alert("해당 유저를 찾을 수 없습니다. 아이디와 비밀번호를 다시 확인해주세요.")
                } else if (errorCode === "auth/invalid-email") {
                    alert("이메일 주소가 올바르지 않습니다.")
                } else if (errorCode === "auth/missing-password") {
                    alert("비밀번호를 입력해주세요.")
                } else {
                    alert("서버와의 통신에 실패하였습니다.")
                }

                setIsLoading(false);
            });
    }

    return (
        <div className="MobileEmailVerification">
            {
                isLoading ?
                    <MobileLoading/> :
                    <Body
                        email={email}
                        password={password}
                        onEmailChange={onEmailChange}
                        onPasswordChange={onPasswordChange}
                        onClick={onClick}
                    />
            }
        </div>
    );
}

function Logo() {
    return (
        <div className="MobileEmailVerification-Logo">
            <img src={logo} alt="logo"/>
        </div>
    );
}

function SendEmail(props) {
    return (
        <div className="MobileEmailVerification-SendEmail">
            <button onClick={props.onClick}>
                이메일 재인증
            </button>
        </div>
    );
}

function Body(props) {
    return (
        <div className={"MobileEmailVerification-Body"}>
            <MobileMenuIcon/>
            <Logo/>
            <form>
                <input
                    className="MobileEmailVerification-email"
                    type="text"
                    placeholder="아이디(이메일 주소)"
                    required
                    value={props.email}
                    onChange={props.onEmailChange}/>
                <input
                    className="MobileEmailVerification-password"
                    type="password"
                    placeholder="비밀번호"
                    required
                    value={props.password}
                    onChange={props.onPasswordChange}/>
            </form>
            <SendEmail onClick={props.onClick}/>
        </div>
    );
}
